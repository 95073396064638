
/* Login button */
.center-card {
  margin: auto;
  width: 50%;
  padding: 10px;
  margin-top: 130px;
  border: 20px;
}

.center-image {
  padding-top: 30px;
  text-align: center;
}


.loginButton {
    cursor: pointer;
    display: block;
    font-size: 1.3em;
    box-sizing: content-box;
    margin: 20px auto 0px;
    width: 70%;
    padding: 15px 20px;
    border-radius: 24px;
    border-color: transparent;
    background-color: #4285f4;
    box-shadow: 0px 16px 60px rgba(78, 79, 114, 0.08);
    position: relative;
  }
  
  .loginButtonText {
    color: white;
    font-weight: bolder;
  }
  
  .loginIcon {
    height: 25px;
    width: 25px;
    margin-right: 0px;
    position: absolute;
    left: 30px;
    align-items: center;
  }