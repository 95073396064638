.footer {
    bottom: 0;
    width: 100%;
    height: 50px;
    text-align: center;
    overflow: hidden;
}

.footer a {
    text-decoration-line: none;
    color: #333333; 
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.footer p {
    font-size: 5px;
    padding-top: 12px;
}
