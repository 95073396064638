.custom-pagination{
  padding-top: 15px;
}
.custom-pagination .page-item .page-link {
  background-color: white;    /* Change background color */
  color: #FF7E0B;              /* Change text color */
  border: #dee2e6;    /* Change border color */
}

.custom-pagination .page-item.active .page-link {
  background-color: #FF7E0B;    /* Change for active page */
  color: white;
  border-color: #dee2e6;
}

.page-heading {
  font-size: 28px;
  color: #333333;
  line-height: 33.6px;
  font-weight: 700;
  margin-top: 40px
}

li {
  font-size: 18px;
  color: #333333;
  line-height: 21.6px;
  font-weight: 700;
  align-self: center;
}

.load-more-section {
  margin-top: 32px;
  margin-bottom: 120px;
  text-align: center;
}

.load-more {
  width: 178px;
  height: 48px;
  font-size: 14px;
  font-weight: 500;
}

.number-section {
  padding-top: 25.5px;
  margin-bottom: 13.5px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}

.view-all-btn {
  margin-top: -10px;
}

.view-all-btn>.btn-link {
  color: #FF7E0B;
}

div>.card {
  border-color: #EFF1F4;
  border-bottom-color: #EFF1F4;
  border-bottom-width: 10px;
}

.card >.card-header {
  background-color: white;
  height: 65px;
  border: none
}

.custom-tabs {
  border-color: #565e64;
  border-bottom-width: 0.5px;
  border-bottom-style: solid;
  padding-top: 20px;
}

.custom-tabs>.nav-item>.nav-link {
  color: black;
  border: 0;
  background-color: #EFF1F4;
  margin-right: 60px;
}

.custom-tabs>.nav-item>.nav-link.active {
  color: #FF7E0B;
  border-bottom: solid;
  background-color: #EFF1F4;
}

.quotes-accordian {
  background-color: #EFF1F4;
}

.table {
  margin-top: 16px;
  border-style: hidden;
  text-align: center;
}

th {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  height: 50px;
  border-bottom: 0;
  background-color: #EFF1F4 !important;
}

td {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  height: 60px;
  vertical-align: middle;
}

tr {
  background-color: white;
  border-color: #EFF1F4 !important;
  border-bottom-width: 8px !important;
  border-bottom-style: solid;
}

td:first-child {
  border-radius: 15px;
  -moz-border-radius: 15px 0 0 15px;
  -webkit-border-radius: 15px 0 0 15px;
}

td:last-child {
  border-radius: 15px;
  -moz-border-radius: 0 15px 15px 0;
  -webkit-border-radius: 0 15px 15px 0;
}

