.nav-bar {
    background-color: white;
    padding-top: 20px;
    padding-left: 100px;
    height: 80px;
}

.nav-item-start {
    margin-left: 80px;
}

.custom-nav-item {
    color: black;
    text-decoration-line: none;
}

.nav-item > .active {
    padding-bottom: 24px;
    border-bottom: 3px solid #FF7E0B;
}

.nav-item-end {
    margin-left: 80px;
    text-align: right;
}

.nav-item-end > .active {
    border-bottom: none;
}
