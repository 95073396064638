.mb-2 {
    margin-top: -10px;
}

.output-text {
    font-size: 16px;
    color: #333333;
}

p {
    font-size: 16px;
    color: #333333;
}
  