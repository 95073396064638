input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.add-space {
  padding: 20px 0;
}

.section-white {
  background-color: white;
  border-radius: 12px;
  padding-left: 10px;
}

.mb-2 {
  margin-top: -10px;
}

.mb-3 {
  padding-top: 18px;
}

p {
  font-size: 16px;
  color: #333333;
}

.add-space .btn {
  margin-right: 18px;
  margin-top: 18px;
  font-size: 14px;
}

.btn-link {
  text-decoration-line: none;
  color: #FF7E0B;
}

.outputs-section {
  width: 644.43px;
  padding-top: 40px;
  overflow-y: scroll;
}

.input-dropdown-size .btn {
  width: 100%;
  margin-right: 0px;
}

.input-buttons {
  border-color: white;
}

.pricing-text {
  text-align: center;
  vertical-align: middle;
  line-height: 650px;
}

.custom-input-row {
  border-bottom-width: 0 !important;
}

.pull-right {
  text-align: right;
}


