.modal-content .modal-header {
  border-bottom: 0;
}

.modal-content .modal-body {
  padding-left: 169px;
  padding-right: 169px;
  padding-bottom: 44px;
  padding-top: 36px;
  text-align: center;
}

.modal-content .modal-footer {
  border-top: 0;
  align-self: center;
  padding-bottom: 64px;
  margin-top: -16px;
}

.modal-heading {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  margin-left: 169px;
  margin-right: 169px;
  margin-top: 47px;
  text-align: center;
  overflow-wrap: normal;
  line-height: 40px;
}

.modal-description {
  text-align: center;
  font-size: 1em;
  padding: 2em;
  padding-bottom: 0;
}

.modal-custom-body {
  margin-left: 31px;
}

.modal-subheading {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  text-align: left;
}
